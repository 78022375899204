import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";

const AboutContainerAbout = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div
            id="about-us"
            className="about-us position-relative mt-10 mb-10 pb-10 pt-10"
        >
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle=""
                                title=""
                            />
                            <p className="mb-5">
                                Our team is composed of seasoned professionals
                                who bring a wealth of knowledge and expertise to
                                the table. We have a proven track record of
                                developing innovative strategies and executing
                                successful campaigns that drive growth and
                                revenue
                            </p>
                            <p>
                                At the heart of our philosophy is a commitment
                                to building strong, long-lasting relationships
                                with our clients. We take the time to understand
                                their unique needs and challenges, and we work
                                collaboratively to develop customized solutions
                                that deliver measurable results
                            </p>
                            <p>
                                We pride ourselves on staying ahead of the curve
                                when it comes to industry trends and best
                                practices. We are constantly learning and
                                refining our skills to ensure that we are always
                                delivering the most effective solutions for our
                                clients
                            </p>
                            <p>
                                Whether you&apos;re looking to launch a new
                                product, expand your customer base, or optimize
                                your collections, we have the expertise and
                                passion to help you achieve your goals. Get in
                                touch today to learn more about how we can help
                                your business thrive
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/2.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainerAbout;
