import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeData from "../../../data/home.json";
import Brand from "../../../components/brand/index";
import SectionTitle from "../../../components/section-title";


const ClientContainer = () => {
    const swiperOption = {
        loop: true,
        speed: 800,
        autoplay: {
            delay: 2000,
        },
        slidesPerView: 4,
        spaceBetween: 0,
        pagination: false,
        navigation: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
            },
            // when window width is >= 640px
            768: {
                slidesPerView: 3,
            },

            // when window width is >= 640px
            992: {
                slidesPerView: 4,
            },
        },
    };
    return (
        <div className="brand-section section-py">
            <div className="container">
            <div className="row">
                    <div className="col-xl-6 col-lg-8 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 pb-10 text-center"
                            subTitle=""
                            title="Our <span class='text-primary'>Clients</span>"
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Swiper  {...swiperOption}>
                            {HomeData[7].client &&
                                HomeData[7].client.map((single, key) => {
                                    return (
                                        <SwiperSlide key={key} >
                                            <Brand key={key} data={single} />
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientContainer;


// {
//     "client":[
//         {
//             "id":1,
//             "industry":"ITES",
//             "brandsImg":["/images/brand-logo/algomage.png","/images/brand-logo/metricstream.jpg"]
//         },
//         {
//             "id":2,
//             "industry":"RETAIL",
//             "brandsImg":["/images/brand-logo/alagar.png","/images/brand-logo/kitser.png"]
//         },
//         {
//             "id":3,
//             "industry":"TELECOM",
//             "brandsImg":["/images/brand-logo/epicon.png"]   
//         },
//         {
//             "id":4,
//             "industry":"E COMMERCE",
//             "brandsImg":["/images/brand-logo/christy.png","/images/brand-logo/spaces.png"]    
//         },
//         {
//             "id":5,
//             "industry":"FMCG & OTHERS",
//             "brandsImg":["/images/brand-logo/lenovo.png","/images/brand-logo/bluestar.png","/images/brand-logo/srichakra.png","/images/brand-logo/dairyparadise.png"]  
//         }
//     ]
// }
