import React from "react";

import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";

const ContactContainer = () => {
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">Get in touch</h3>
                            {/* <p>
                                You can reach us by filling out the form below
                                or by using any of the contact information
                                provided on this page. We promise to respond to
                                your inquiry as soon as possible and provide you
                                with the support you need.
                            </p> */}
                        </div>

                        {/* <ContactForm /> */}
                        <iframe
                            title="contact-map"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.135501441391!2d80.2576132!3d13.0494245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526765ec977d1b%3A0xd0035e9dcd7ff2ba!2sBlack%20Coffee%20Brands!5e0!3m2!1sen!2sin!4v1684930681998!5m2!1sen!2sin"
                            width="100%"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>

                    <div className="col-xl-5 col-lg-6 mb-7 offset-xl-1">
                        <div className="contact-address text-center">
                            {ContactData &&
                                ContactData.map((single, key) => {
                                    return (
                                        <ContactInfo key={key} data={single} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
