import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";
import SocialIcon from "../../social-icon";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>

                    <nav id="offcanvasNav" className="offcanvas-menu">
                        <ul>
                            <li>
                                <NavLink exact to="/">
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/about-us"}
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/services"}
                                >
                                    Services
                                </NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    {/* <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service"
                                            }
                                        >
                                            service
                                        </NavLink>
                                    </li> */}
                                    {/* <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/"
                                            }
                                        >
                                            single service
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/sales-marketing"
                                            }
                                        >
                                            Sales &amp; Marketing Strategic
                                            Solutions
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/servic/digital-marketing"
                                            }
                                        >
                                            Digital Marketing
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/contact-center"
                                            }
                                        >
                                            Contact Center Lead Servicing
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/product-development"
                                            }
                                        >
                                            Product Development
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/reasearch-analytics"
                                            }
                                        >
                                            Research &amp; Analytics
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/land-acquisition"
                                            }
                                        >
                                            Land Acquisition Feasibility
                                            Research
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/customer-life-cycle"
                                            }
                                        >
                                            Customer Life-Cycle Management
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/service/managed-services"
                                            }
                                        >
                                            Managed Services/Events Management
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li>
                                <NavLink to="/">Pages</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            to={process.env.PUBLIC_URL + "/faq"}
                                        >
                                            faq
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL + "/team"
                                            }
                                        >
                                            team
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> */}
                            {/* <li>
                                <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                                    Blog
                                </NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL + "/blog"
                                            }
                                        >
                                            blog
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/blog-left-sidebar"
                                            }
                                        >
                                            blog grid left sidebar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/blog-right-sidebar"
                                            }
                                        >
                                            blog grid right sidebar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/blog-details/1"
                                            }
                                        >
                                            blog details
                                        </NavLink>
                                    </li>
                                </ul>
                            </li> */}
                            <li>
                                <NavLink
                                    to={process.env.PUBLIC_URL + "/contact-us"}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="header-social-links d-flex">
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.facebook.com/BlackCoffeeBrands"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                    <li>
                                        <a
                                            className={`footer-social-link`}
                                            href="https://twitter.com/BlackCoffeeBrds"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <svg
                                                id="fi_5968958"
                                                width={16}
                                                height={16}
                                                enableBackground="new 0 0 1226.37 1226.37"
                                                viewBox="0 0 1226.37 1226.37"
                                                fill="currentcolor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.linkedin.com/company/black-coffee-brands/"
                                            icon="icofont-linkedin"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://instagram.com/blackcoffee.brands?igshid=ZmI3MzJjNWI="
                                            icon="icofont-instagram"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.youtube.com/@BlackCoffeeBrands"
                                            icon="icofont-youtube"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://in.pinterest.com/BlackCoffeeBrands/"
                                            icon="icofont-pinterest"
                                        />
                                    </li>
                                    {/* <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.whatsapp.com/"
                                            icon="icofont-whatsapp"
                                        />
                                    </li> */}
                                </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
