import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AccordionContainer from "../containers/accordion";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const TermsPage = () => {
    return (
        <React.Fragment>
        <Layout>
            <SEO title="Terms & Conditions | Black Coffee Brands" />
            <div className="wrapper">
                <Header />
                <PageBanner
                    title="Terms & Conditions"
                    excerpt="Terms & Conditions- Black Coffee Brands 480 Degree Media LLP"
                    image="./images/service/2.png"
                />
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <h3>1. Introduction</h3>
                    <p>1.1 These Terms and Conditions govern your use of our social media marketing services. By using our services, you accept these Terms and Conditions in full.</p>
                    <h3>2. Services</h3>
                    <p>2.1 Our agency provides social media marketing services, including but not limited to social media account management, content creation, and advertising campaigns.</p>
                    <p>2.2 We reserve the right to refuse service to anyone for any reason at any time.</p>
                    <h3>3. User Obligations</h3>
                    <p>3.1 You agree to provide us with all necessary access to social media accounts and other digital platforms as needed for the provision of our services.</p>
                    <p>3.2 You agree not to use our services for any unlawful purpose.</p>
                    <h3>4. Payments</h3>
                    <p>4.1 Fees for our services are due as per the agreed payment schedule.</p>
                    <p>4.2 Late payments may result in a suspension of services.</p>
                    <h3>5. Intellectual Property</h3>
                    <p>5.1 We retain ownership of all intellectual property rights in the materials we produce.</p>
                    <p>5.2 You grant us a license to use your brand assets and intellectual property for the purpose of providing our services.</p>
                    <h3>6. Confidentiality</h3>
                    <p>6.1 Both parties agree to keep all confidential information received from the other party secure and confidential.</p>
                    <h3>7. Limitation of Liability</h3>
                    <p>7.1 Our liability for any loss or damage suffered by you as a result of our breach of these Terms or negligence is strictly limited to the amount you have paid us for our services.</p>
                    <h3>8. Termination</h3>
                    <p>8.1 Either party may terminate the agreement with written notice. Specific terms of termination and any applicable fees should be detailed here.</p>
                    <h3>9. Governing Law</h3>
                    <p>9.1 These Terms and Conditions shall be governed by and construed in accordance with Indian Laws.</p>
                    <h3>10. Amendments</h3>
                    <p>10.1 We reserve the right to update or change these Terms and Conditions at any time.</p>
                    
                </div>
                </div></div>
                <Footer />
                <ScrollToTop />
            </div>
        </Layout>
    </React.Fragment>
    );
};

export default TermsPage;
