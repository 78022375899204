import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AccordionContainer from "../containers/accordion";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const Refund = () => {
    return (
        <React.Fragment>
        <Layout>
            <SEO title="Refund & Cancellation Policy | Black Coffee Brands" />
            <div className="wrapper">
                <Header />
                <PageBanner
                    title="Refund & Cancellation Policy"
                    excerpt="Refund & Cancellation Policy- Black Coffee Brands 480 Degree Media LLP"
                    image="./images/service/2.png"
                />
                <div className="container">
                <div className="row">
                <div className="col-md-12">
                <h3>1. Introduction</h3>
                <p>1.1 This Refund Policy outlines the conditions under which Black Coffee Brands 480 Degree Media LLP will issue refunds for our social media marketing services.</p>
                <h3>2. Service Cancellation</h3>
                <p>2.1 If you are not satisfied with our services, you may cancel at any time. However, we require 30 Days notice for service cancellation.</p>
                <p>2.2 Refunds for cancelled services will be considered on a case-by-case basis and are at our sole discretion.</p>
                <h3>3. Non-Refundable Services</h3>
                <p>3.1 Certain services, such as completed campaigns, ad spend, or specific project work, are non-refundable once delivered or initiated.</p>
                <h3>4. Refund Eligibility</h3>
                <p>4.1 To be eligible for a refund, you must submit a refund request in writing within 15 Days of service delivery.</p>
                <p>4.2 The request must include a detailed explanation of the reason for the refund request.</p>
                <h3>5. Processing Refunds</h3>
                <p>5.1 Eligible refunds will be processed within 10 Days of the refund request approval.</p>
                <p>5.2 Refunds will be credited to the original method of payment.</p>
                <h3>6. Partial Refunds</h3>
                <p>6.1 In some cases, we may offer a partial refund, which will be calculated based on the services rendered up to the time of cancellation.</p>
                <h3>7. Changes to the Policy</h3>
                <p>7.1 We reserve the right to modify this Refund Policy at any time.</p>
                <h3>8. Contact Us</h3>
                <p>8.1 If you have any questions about this Refund Policy, please contact us at info@blackcoffeebrands.co.in.</p>
                </div>
                </div></div>
                <Footer />
                <ScrollToTop />
            </div>
        </Layout>
    </React.Fragment>
    );
};

export default Refund;
