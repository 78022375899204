import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ClientContainer from "../containers/global/clientele";

const HomePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Home | Black Coffee Brands" />
                <div className="wrapper">
                    <Header />
                    <IntroContainer />
                    {/* <BrandContainer /> */}
                    <IconBoxContainer classOption="section-pb mt-10" />
                    <HomeAboutContainer />
                    <ServiceListContainer classOption="mb-10" />
                    {/* <TestimonialContainer /> */}
                    <FunFactContainer classOption="mt-10 pt-10" />
                      <BrandContainer />
                      <ClientContainer/>
                    {/* <TeamContainer classOption="section-pb" /> */}
                    {/* <HomeBlog />
                    <NewsletterArea /> */}
                    <div className="w-100">
                        <iframe title="bcb_map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.136746709597!2d80.2576068!3d13.0494047!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526765ec977d1b%3A0xd0035e9dcd7ff2ba!2sBlack%20Coffee%20Brands!5e0!3m2!1sen!2sin!4v1703784353317!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
