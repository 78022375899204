import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AccordionContainer from "../containers/accordion";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
        <Layout>
            <SEO title="Privacy Policy | Black Coffee Brands" />
            <div className="wrapper">
                <Header />
                <PageBanner
                    title="Privacy Policy"
                    excerpt="Privacy Policy- Black Coffee Brands 480 Degree Media LLP"
                    image="./images/service/2.png"
                />
                <div className="container">
                <div className="row">
                <div className="col-md-12">
                <h3>1. Introduction</h3>
                    <p>1.1 This Privacy Policy outlines how Black Coffee Brands 480 Degree Media LLP collects, uses, and protects your personal information when you use our social media marketing services.</p>
                    <h3>2. Information Collection</h3>
                    <p>2.1 We collect information you provide directly to us, such as your name, email address, phone number, and payment information.</p>
                    <p>2.2 We may also collect indirect information related to your social media profiles and online marketing activities.</p>
                    <h3>3. Use of Information</h3>
                    <p>3.1 The information we collect is used to provide and improve our services, process payments, communicate with you, and comply with legal requirements.</p>
                    <h3>4. Information Sharing</h3>
                    <p>4.1 We may share your information with third-party service providers who assist us in our services.</p>
                    <p>4.2 We will not sell, rent, or lease your personal information to others, except as described in this policy.</p>
                    <h3>5. Data Security</h3>
                    <p>5.1 We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</p>
                    <h3>6. International Transfer</h3>
                    <p>6.1 Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ.</p>
                    <h3>7. Your Rights</h3>
                    <p>7.1 You have the right to access, correct, or delete your personal information. You can also object to or restrict certain processing of your data.</p>
                    <h3>8. Cookies and Tracking Technologies</h3>
                    <p>8.1 We may use cookies and similar tracking technologies to track activity on our services and hold certain information.</p>
                    <h3>9. Changes to This Policy</h3>
                    <p>9.1 We reserve the right to modify this policy at any time. We will notify you of any changes by posting the new policy on this page.</p>
                    <h3>10. Contact Us</h3>
                    <p>10.1 If you have any questions about this Privacy Policy, please contact us at info@blackcoffeebrands.co.in.</p>
                </div>
                </div></div>
                <Footer />
                <ScrollToTop />
            </div>
        </Layout>
    </React.Fragment>
    );
};

export default PrivacyPolicy;
