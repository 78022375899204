import PropTyps from "prop-types";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Team = ({ data, showDescription }) => {
    return (
        <div className="new-team-members-list">
            <img
                src={process.env.PUBLIC_URL + data.thumb}
                alt="images"
                width="150"
            />
            <h3 className="title">{data.name}</h3>
            <span
                className="my-3"
                dangerouslySetInnerHTML={{ __html: data.designation }}
            ></span>
            {showDescription && (
                <Popup
                    trigger={
                        <button className="btn text-center w-full">
                            Read More
                        </button>
                    }
                    modal
                >
                    <div className="header text-center m-2"> {data.name} </div>
                    <div
                        className="content teamDescription text-start m-4 overflow-auto"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                    ></div>
                </Popup>
            )}
        </div>
    );
};

Team.propTypes = {
    data: PropTyps.object,
    showDescription: PropTyps.bool,
};

export default Team;
