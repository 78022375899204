import PropTypes from "prop-types";
import SectionTitle from "../section-title";

const Brand = ({ data }) => {
    return (
        <div className="single-brand" style={{padding:"0px 50px",display:'flex', flexDirection:"column",height:'100%',alignItems:'center'}}>
            <img src={process.env.PUBLIC_URL + data.image} alt="brand logo"/>
            <p dangerouslySetInnerHTML={{__html:data.title}}></p>
        </div>
    );
};

Brand.propTypes = {
    data: PropTypes.object,
};

export default Brand;
